import './LandingPage.css';
import React, { useState, useEffect } from 'react';


const LandingPage = () => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 850);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 850);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });


  return (
    <section className="landing-page-wrapper">
      <nav id="nav-bar">
      </nav>
      <div id="title-wrapper">
        <h1 className="title-heading">
          Hey, I'm <span className="highlight-primary">Kevin Mao</span>. <br />
          <span className="title-heading-secondary">I code smart contracts</span> <br />
          <span className="title-heading-secondary">& sculpt ideas.</span>
        </h1>
      </div>

      {/* RENDER IF SCREENWIDTH IS ABOVE 768 PX (TABLET)*/}
      {isDesktop ? (
        <>
          <div className="socials-pane" orientation="left">
            <ul id="socials-list">
              <li><a href="https://github.com/kmaox" rel="noreferrer" target="_blank"><img alt="github" src="./github.svg"></img></a></li>
              <li><a href="https://twitter.com/kmao37" rel="noreferrer" target="_blank"><img alt="twitter" src="./twitter.svg"></img></a></li>
            </ul>
          </div>
        </>
      ) : (
        <></>
      )}

    </section>
  );
}

export default LandingPage;
